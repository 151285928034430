import { useState } from "react";
import { URL_CONSTANTS } from "../../axios/urlLinksThemes";
import {
  IALLPRODUCTS,
  IBRANDLOGO,
  ICATEGORY,
  IHomePageSlider,
} from "../../shared/commonUtils";
import { httpGet } from "../../axios/axiosUtils";
import { setBussinessToken } from "../../shared/helpersMethod";
const useGetToken = () => {
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [userId, setUserId] = useState<number>();
  // IALLPRODUCTS
  // const {getUserToken,userToken} = useBussiness();
  // "theme":"1","template":"1", "page":"home","element":"slider"
  const getToken = async () => {
    setLoading(true);

    var domainName = window.businessByDomain;
    var newToken = "";
    try {
      //@ts-ignore
      const res = await httpGet(
        URL_CONSTANTS.get("URL_GET_TOKEN")! + domainName
      );
      // /rjs/business/by/name/ncd
      if (res) {
        setAccessToken(res.data.access_token);
        setUserId(res.user_id);
        newToken = res.data.access_token;
        // parseLocalData.shopInfo = shopData;
        setBussinessToken(newToken);
      }
      setLoading(false);
      return newToken;
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return {
    getToken,
    accessToken,
    userId,
    loading,
  };
};
export default useGetToken;
