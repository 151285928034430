import PropTypes, { object } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/useReduxMethods";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getDiscountPrice, isProductAvailable } from "../../helpers/product";
import ProductModal from "./ProductModal";
import Rating from "./sub-components/ProductRating";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
// import { addToCompare } from "../../store/slices/compare-slice";
import {
  NEWBADGE,
  NEWBADGEAR,
  OFFERTAGIMG,
  Product,
} from "../../shared/commonUtils";
import {
  getUserPrefersLang,
  numberWithCommas,
} from "../../shared/helpersMethod";
import HeartEmpty from "../../assets/svgs/HeartEmpty";
import HeartFill from "../../assets/svgs/HeartFill";
import useTranslationHook from "../../hooks/useTranslationHook";
import {
  CartBtnIcon,
  CartBtnIconWhite,
  DiscountBadge,
} from "../../assets/svgs/svgs";
interface IProductGridSingleThree {
  product: Product;
  fullWidth: boolean;
  currency: any;
  cartItem: any;
  wishlistItem: any;
  compareItem: any;
  spaceBottomClass: any;
}
const ProductGridSingleThree: React.FC<IProductGridSingleThree> = (
  props: IProductGridSingleThree
) => {
  const {
    product,
    currency,
    fullWidth,
    cartItem,
    wishlistItem,
    compareItem,
    spaceBottomClass,
  } = props;
  const [modalShow, setModalShow] = useState(false);
  const { t } = useTranslationHook();
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const finalProductPrice = +(
    parseFloat(product.price) * currency.currencyRate
  ).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice !== null ? discountedPrice * currency.currencyRate : 0
  ).toFixed(2);
  const userLng = getUserPrefersLang();
  const dispatch = useDispatch();
  const showBrands =
    bussinessData?.homepage_configurations?.display_sections?.display_brands;
  const redirectionLink = `/product/${product.slug}/${product.id}`;
  const productAvailable = isProductAvailable(product, bussinessData);
  const RenderPriceButton = () => {
    return (
      <>
        {product?.variations.length !== 0 && (
          <button
            className={`bold product-view-details ${[140].includes(bussinessData.businessID) ? "full-color-button" : ""} ${fullWidth && "full-width-product"} `}
            disabled={cartItem !== undefined && cartItem.quantity > 0}
          >
            <Link to={redirectionLink}>{t("txt_select_option")}</Link>
          </button>
        )}
        {product?.variations.length === 0 && (
          <>
            {productAvailable.isAvailable ? (
              <button
                className={`product-view-details ${[140].includes(bussinessData.businessID) ? "full-color-button" : ""} ${fullWidth && "full-width-product"} `}
                disabled={cartItem !== undefined && cartItem.quantity > 0}
                onClick={() =>
                  dispatch(
                    addToCart({
                      ...product,
                      translation: t("txt_added_to_cart"),
                      discountedPrice: discountedPrice,
                      actualPrice: finalProductPrice,
                    })
                  )
                }
              >
                <i>
                  <i className="">
                    {[140].includes(bussinessData.businessID) ? (
                      <CartBtnIconWhite />
                    ) : (
                      <CartBtnIcon fill="white" />
                    )}
                  </i>
                </i>
                <span className="mr-2 ml-2 mt-5 bold">
                  {cartItem !== undefined
                    ? t("txt_added")
                    : t("txt_add_to_cart")}
                </span>
              </button>
            ) : (
              <button
                className={` bold product-view-details ${[140].includes(bussinessData.businessID) ? "full-color-button" : ""} btn-disabled`}
                disabled
              >
                <text> {t(`${productAvailable.reason}`)}</text>
              </button>
            )}
          </>
        )}
      </>
    );
  };
  return (
    <Fragment>
      <div className={`fav-image-wrapper ${fullWidth && "full-width-product"}`}>
        <div className="pro-wishlist-2   fav-mobile-button ">
          <button
            className={`${wishlistItem !== undefined ? "active" : ""} add-to-wishlist-bg`}
            disabled={wishlistItem !== undefined}
            title={
              wishlistItem !== undefined
                ? "Added to wishlist"
                : "Add to wishlist"
            }
            onClick={() =>
              dispatch(
                addToWishlist({
                  ...product,
                  translation: t("txt_added_to_cart"),
                })
              )
            }
          >
            {wishlistItem !== undefined ? <HeartFill /> : <HeartEmpty />}
          </button>
        </div>
        <div>
          {product.discount !== null || product.new ? (
            <div className={`product-img-badges`}>
              {product.discount !== null ? (
                <>
                  <div className="tagstyle">
                    <img
                      loading="lazy"
                      className="hot-off"
                      src={OFFERTAGIMG}
                      alt="hot offer"
                      width="100"
                    />
                    <div>
                      {product.discount.discount_type === "percentage" ? (
                        <span className="pink bold">
                          {product.discount.discount_amount}%
                        </span>
                      ) : (
                        <span className="pink bold">
                          {t("txt_sale")}
                          {/* - {currency.currencySymbol}{" "}
                    {product.discount.discount_amount} */}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                // <>
                //   {product.discount.discount_type === "percentage" ? (
                //     <span className="pink">
                //       {product.discount.discount_amount} %
                //     </span>
                //   ) : (
                //     <span className="pink">
                //       {t("txt_sale")}
                //       {/* - {currency.currencySymbol}{" "}
                //       {product.discount.discount_amount} */}
                //     </span>
                //   )}
                // </>
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className="product-img">
            <Link to={redirectionLink}>
              <LazyLoadImage
                effect="blur"
                decoding="async"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
                className="default-img"
                src={process.env.PUBLIC_URL + product.image[0]}
                alt={"default-img-" + product.name}
              />
              {/* <img
                loading="lazy"
                className="default-img"
                src={process.env.PUBLIC_URL + product.image[0]}
                alt={"default-img-" + product.name}
              /> */}
              {product?.image?.length > 1 ? (
                <LazyLoadImage
                  decoding="async"
                  className="hover-img d-none"
                  src={process.env.PUBLIC_URL + product.image[1]}
                  alt={"hover-img-" + product.name}
                />
              ) : (
                // <img
                //   loading="lazy"
                //   className="hover-img d-none"
                //   src={process.env.PUBLIC_URL + product.image[1]}
                //   alt={"hover-img-" + product.name}
                // />
                <LazyLoadImage
                  className="hover-img d-none"
                  src={process.env.PUBLIC_URL + product.image[0]}
                  alt={product.name}
                />
                // <img
                //   loading="lazy"
                //   className="hover-img d-none"
                //   src={process.env.PUBLIC_URL + product.image[0]}
                //   alt={product.name}
                // />
              )}
            </Link>

            {/* {product.product_features &&
              product.product_features.length > 0 && (
                <>
                  {
                    <div className={`feature-tag-container `}>
                      {product.product_features.map((item, index) => (
                        <>
                          {item !== "new" && (
                            <span key={index} className="feature-tag">
                              {item}
                            </span>
                          )}
                        </>
                      ))}
                    </div>
                  }
                </>
              )} */}
            {product.product_features &&
              product.product_features.length > 0 && (
                <>
                  {
                    <div className={`feature-new-badge`}>
                      {product.product_features.map((item, index) => (
                        <>
                          {item === "new" && (
                            <div className="tagstyle">
                              <img
                                loading="lazy"
                                className="hot-off"
                                src={userLng == "ar" ? NEWBADGEAR : NEWBADGE}
                                alt="hot offer"
                                width="100"
                              />
                              <div>
                                <span className="pink bold">
                                  {t("txt_new")}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  }
                </>
              )}
          </div>
        </div>
      </div>

      <div
        className={clsx("product-wrap-2 pro-glaucous-color ", spaceBottomClass)}
      >
        <div className={`${showBrands === "Yes" && "brand-name-wrapper"}`}>
          {showBrands === "Yes" && product?.brand !== null ? (
            <div>
              <img
                loading="lazy"
                className="brand-logo-img"
                src={product?.brand?.image}
              />
            </div>
          ) : (
            ""
          )}
          <div
            className={`product-content-2 ${fullWidth && "align-items-end"}`}
          >
            <div className="title-price-wrap-2">
              <div>
                <Link className="text-two-line" to={redirectionLink}>
                  {product.name}
                </Link>
              </div>

              <div className="pro-details-rating ">
                <Rating ratingValue={5} />
              </div>
              {product.price != "0" && (
                <div className=" price-2  " style={{ fontSize: 10 }}>
                  {discountedPrice !== null ? (
                    <Fragment>
                      <div className="d-flex flex-column">
                        <span className="old mr-5 ml-5 ">
                          <span className=" ">
                            <span> {numberWithCommas(finalProductPrice)}</span>
                            <span>{currency.currencySymbol}</span>

                            {/* <span className="">{currency.currencySymbol}</span> */}
                          </span>
                        </span>
                        <span className="mobile-price bold text-danger">
                          <span>{numberWithCommas(finalDiscountedPrice)}</span>
                          <span> {currency.currencySymbol} </span>
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    <span>
                      <span className="mobile-price bold">
                        {numberWithCommas(finalProductPrice)}{" "}
                      </span>
                      <span className="mobile-price">
                        {currency.currencySymbol} {""}
                      </span>
                    </span>
                  )}
                </div>
              )}
            </div>
            {fullWidth && <RenderPriceButton />}
          </div>
          {!fullWidth && <RenderPriceButton />}
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        productId={product.id}
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedPrice={discountedPrice}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      />
    </Fragment>
  );
};

ProductGridSingleThree.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({}),
};

export default ProductGridSingleThree;
