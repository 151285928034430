import React from "react";

const HeartEmpty: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.26586 11.9075C7.55372 12.613 6.45077 12.613 5.73863 11.9075L1.49139 7.69955C-1.34994 4.85817 2.36033 -1.41946 7.00224 3.22245C11.636 -1.4113 15.3463 4.86643 12.5131 7.69955L8.26586 11.9075Z"
        stroke="#C1C1C1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeartEmpty;
