// @ts-ignore
/* eslint-disable */
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
// import { setProducts } from "./store/slices/product-slice"
// import products from "./data/products.json";
import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import { HelmetProvider } from "react-helmet-async";

// store.dispatch(setProducts(products));

const container = document.getElementById("root");
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </PersistProvider>
  </Provider>
);
