import { useState } from "react";
import { URL_CONSTANTS } from "../../axios/urlLinksThemes";
import { httpPost } from "../../axios/axiosUtils";
import {
  checkBussinessToken,
  getCustomerInfoFromStroge,
  showErrortMessage,
  showSuccesstMessage,
} from "../../shared/helpersMethod";
import { IAqsatiCustomer, IGATEWAYS } from "../../shared/commonUtils";
import { useAppDispatch, useAppSelector } from "../useReduxMethods";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { setPaymentData } from "../../store/slices/gateways-slice";
const useGateWays = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const customerInfo = getCustomerInfoFromStroge();
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  var aqsatiDummyRes = {
    type: "success",
    identity: "6330153657143355",
    customer: {
      customerName: "حسن حافظ عبيد ناصر",
      phoneNumber: "7707833940",
      installmentOptions: {
        minMonth: 1,
        maxMonth: 36,
        netPayment: 6000000,
        dedections: 5005059.67,
        allowableSalary: 994940.33,
        countOfMonth: 36,
      },
    },
    trnid: "2024Oct08092157_9210cb2ce7046c48d7ec161fec772182",
  };
  const [billingGateWays, setBillingGateWays] = useState<IGATEWAYS | undefined>(
    {}
  );
  const [transactionId, setTransactionId] = useState<string>("");
  const getBillingGateWays = async () => {
    setLoading(true);
    try {
      const bussinessData = await checkBussinessToken();

      const res = await httpPost(
        URL_CONSTANTS.get("URL_GET_GATEWAYS")!,

        {},
        {},
        bussinessData.accessToken
      );
      if (res.type == "success") {
        setBillingGateWays(res.gateways);
        dispatch(setPaymentData(res.gateways));
      }

      setLoading(false);
    } catch (error: any) {
      console.log(error.message);

      setLoading(false);
    }
  };

  return {
    getBillingGateWays,
    billingGateWays,
    gateWaysLoading: loading,
  };
};
export default useGateWays;
