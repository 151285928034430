import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGATEWAYS } from "../../shared/commonUtils";

const gateWaysSlice = createSlice({
  name: "paymentData",
  initialState: {
    paymentData: {} as IGATEWAYS,
  },
  reducers: {
    setPaymentData: (state, action) => {
      const paymentData1 = action.payload;
      state.paymentData = paymentData1;
    },
  },
});

export const { setPaymentData } = gateWaysSlice.actions;
export default gateWaysSlice.reducer;
