import React, { Fragment, useEffect, useRef } from "react";
import LayoutTwo from "../../layouts/LayoutTwo";
import { useNavigate } from "react-router-dom";
import LayoutOne from "../../layouts/LayoutOne";
import { useAppSelector } from "../../hooks/useReduxMethods";

const WindrawLink: React.FC = () => {
  const iframeRef = useRef(null);
  const navigate = useNavigate();
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
    script.onload = () => {
      if (window.jotformEmbedHandler) {
        window.jotformEmbedHandler(
          "iframe[id='JotFormIFrame-250701288211448']",
          "https://form.jotform.com/"
        );
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (bussinessData && ![140].includes(bussinessData.businessID)) {
      navigate("/404");
    }
  }, [bussinessData]);
  return (
    <Fragment>
      <LayoutOne hideFooter={true}>
        <div style={{ background: "#F1EEEB" }}>
          <iframe
            ref={iframeRef}
            id="JotFormIFrame-250701288211448"
            title="اشتري أكثر واربح المزيد من هدايا رمضان"
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/250701288211448"
            frameBorder="0"
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              height: "539px",
              border: "none",
            }}
            scrolling="no"
          />
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default WindrawLink;
