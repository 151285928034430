import { useState } from "react";
import { URL_CONSTANTS } from "../../axios/urlLinksThemes";
import { ICATEGORY } from "../../shared/commonUtils";
import { useDispatch } from "react-redux";
import { httpPostWithAuth } from "../../axios/axiosUtils";
import {
  getBussinessData,
  getUserPrefersLang,
} from "../../shared/helpersMethod";
import { addHomePageCategories } from "../../store/slices/categories-nav-menu";
// import cogoToast from "cogo-toast";
// import { getBussinessData } from "../../shared/helpersMethod";
const useGetHomeCategory = () => {
  const dispath = useDispatch();
  const [loading, setLoading] = useState(false);
  const [homePageCategories, setHomePageCategories] = useState<ICATEGORY[]>([]);
  const [mobileMenu, setMobileMenu] = useState<ICATEGORY[]>([]);
  const userLanguage = getUserPrefersLang();
  const queryParamLang = `?lang=${userLanguage}`;

  //   const bussinessToken = getBussinessData();

  const getHomePageCategory = async (test: string, isMobile: string) => {
    const bussinessData = getBussinessData();
    console.log(test);
    setLoading(true);
    try {
      const res = await httpPostWithAuth(
        URL_CONSTANTS.get("URL_Get_Categories")! +
          `${userLanguage !== null ? queryParamLang : ""}`,
        {},
        bussinessData.accessToken
      );
      setLoading(false);
      if (res.type === "error") {
        // cogoToast.error(res.message)
        return;
      }
      if (res.type !== "error" && res.data) setHomePageCategories(res);
      dispath(addHomePageCategories(res));
    } catch (error: any) {
      // setBussinessData(getBussinessData())
      console.log(error.message);
      if (error) {
      }
      setLoading(false);
    }
  };
  const getMobileMenu = async (test: string, isMobile: string) => {
    const bussinessData = getBussinessData();
    setLoading(true);
    try {
      const res = await httpPostWithAuth(
        URL_CONSTANTS.get("URL_Get_Categories")! +
          `${userLanguage !== null ? queryParamLang : ""}&mobileMenu=Y`,
        {},
        bussinessData.accessToken
      );
      setLoading(false);
      if (res.type === "error") {
        // cogoToast.error(res.message)
        return;
      }
      if (res) {
        setMobileMenu(res);
      }
    } catch (error: any) {
      // setBussinessData(getBussinessData())
      console.log(error.message);
      if (error) {
      }
      setLoading(false);
    }
  };
  return {
    homePageCategories,
    getHomePageCategory,
    mobileMenuLoading: loading,
    mobileMenu,
    getMobileMenu,
  };
};
export default useGetHomeCategory;
