import { Fragment, useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/useReduxMethods";
import Marquee from "react-fast-marquee";
import { getUserPrefersLang } from "../../shared/helpersMethod";
import { useLocation } from "react-router-dom";
const AnnouncementBar = ({ extraClass /* visible, setVisible*/ }) => {
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const usrLang = getUserPrefersLang(); // Detect Arabic or other languages
  const headerBanner = bussinessData?.site_settings?.siteHeaderDynamicText;
  const [isMobile, setIsMobile] = useState(false);
  const [visible, setVisible] = useState(true);
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const location = useLocation(); // To watch for route changes
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile condition
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    if (header) setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const transparentBar = document.querySelector(".transparent-bar");
      const headerArea = document.querySelector(".header-area");

      if (headerArea) {
        if (visible && headerArea) {
          headerArea.style.marginTop = "40px";
          observer.disconnect(); // Stop observing once found
        } else {
          headerArea.style.marginTop = "0px";
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup on unmount
  }, [visible]);
  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    if (headerBanner)
      if (header && scroll > headerTop) {
        if (visible) {
          header.style.top = "40px"; // Push below announcement bar
        } else {
          header.style.top = "0px"; // Reset when announcement is hidden
        }
      }
  }, [visible, scroll]);
  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  if (!headerBanner || headerBanner.length === 0 || !headerBanner[0])
    return null;
  if (!visible) return null;

  return (
    <Fragment>
      <div className={`announcement-bar ${visible ? "visible" : ""}`}>
        <div dir="ltr" style={{ width: "100%" }}>
          <Marquee
            speed={70}
            direction={usrLang === "ar" ? "right" : "left"}
            pauseOnHover={isMobile ? false : true}
            // loop={0}
            style={{ whiteSpace: "nowrap", display: "flex" }}
          >
            {headerBanner.map((item, index) => (
              <span
                key={index}
                style={{
                  margin: "0 20px",
                  color: "white",
                  display: "inline-block",
                  minWidth: "max-content",
                }}
              >
                {item}
              </span>
            ))}
          </Marquee>
        </div>

        <button
          onClick={() => {
            const transparentBar = document.querySelector(".transparent-bar");
            const headerArea = document.querySelector(".header-area");
            if (headerArea) {
              headerArea.style.marginTop = "0px";
            }
            setVisible(false);
          }}
          className="marquee-close-btn"
        >
          ✕
        </button>
      </div>
    </Fragment>
  );
};

export default AnnouncementBar;
