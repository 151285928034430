import React, { useState } from "react";
import { IGOOGLERATING } from "../../shared/commonUtils";
import { httpGetWithAuth, httpPostWithAuth } from "../../axios/axiosUtils";
import { URL_CONSTANTS } from "../../axios/urlLinksThemes";
import { useAppSelector } from "../useReduxMethods";
import { checkBussinessToken } from "../../shared/helpersMethod";
const useHomePageReviews = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const [ratingsData, setRatingData] = useState<IGOOGLERATING>();

  const getBussinessRating = async () => {
    const bussinessToken = await checkBussinessToken();

    setLoading(true);
    try {
      const response = await httpGetWithAuth(
        URL_CONSTANTS.get("URL_GET_BUSSINESS_RATING") +
          `?bName=${bussinessData.homepage_configurations.display_sections.display_google_review_input}`,
        undefined,
        bussinessToken.accessToken
      );
      if (response && response.type === "success") {
        setRatingData(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return {
    getBussinessRating,
    ratingsData,
  };
};

export default useHomePageReviews;
