import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ProgressState {
  bussinessApiLoading: boolean;
  bussinessApiSuccess: boolean;
  bussinessApiFailure: boolean;
}
const initialState: ProgressState = {
  bussinessApiFailure: false,
  bussinessApiLoading: false,
  bussinessApiSuccess: false,
};

const progressState = createSlice({
  name: "progressState",
  initialState,
  reducers: {
    setBussinessApiLoading: (state, action) => {
      state.bussinessApiLoading = true;
      state.bussinessApiSuccess = false;
      state.bussinessApiFailure = false;
    },
    setBussinessApiSuccess(state, action) {
      state.bussinessApiLoading = false;
      state.bussinessApiSuccess = true;
      state.bussinessApiFailure = false;
    },
    setBussinessApiFailure(state, action) {
      state.bussinessApiLoading = false;
      state.bussinessApiSuccess = false;
      state.bussinessApiFailure = true;
    },
  },
});

export const {
  setBussinessApiLoading,
  setBussinessApiSuccess,
  setBussinessApiFailure,
} = progressState.actions;
export default progressState.reducer;
