import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHOMEPAGEData } from "../../shared/commonUtils";

const bussinessDataSlice = createSlice({
  name: "bussinessData",
  initialState: {
    bussinessData: {} as IHOMEPAGEData,
  },
  reducers: {
    setBussinessData: (state, action: PayloadAction<IHOMEPAGEData>) => {
      const bussinessData1 = action.payload;
      state.bussinessData = bussinessData1;
    },
  },
});

export const { setBussinessData } = bussinessDataSlice.actions;
export default bussinessDataSlice.reducer;
