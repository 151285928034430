import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import HeaderTwo from "../wrappers/header/HeaderTwo";
import FooterOne from "../wrappers/footer/FooterOne";
import ScrollToTop from "../components/scroll-to-top";
import AnnouncementBar from "../wrappers/announcement-bar/AnnouncementBar";
import { useAppSelector } from "../hooks/useReduxMethods";

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  footerPadding,
  headerPaddingClass,
  hideFooter,
  headerPositionClass,
}) => {
  const [visible, setVisible] = useState(true);
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const headerBanner = bussinessData?.site_settings?.siteHeaderDynamicText;
  return (
    <Fragment>
      {headerBanner && headerBanner.length > 0 && headerBanner[0] !== "" && (
        <AnnouncementBar visible={visible} setVisible={setVisible} />
      )}

      <HeaderOne
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
      />
      {children}
      {!hideFooter && (
        <FooterOne
          footerPadding={footerPadding}
          backgroundColorClass="bg-gray"
          spaceTopClass="pt-100"
          spaceBottomClass="pb-70"
        />
      )}
      <ScrollToTop />
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.node,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default LayoutOne;
