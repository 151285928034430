import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import {
  getReqJsonModalGA4WithoutReqData,
  TrackGoogleAnalyticsEvent,
} from "../../helpers/GT4Helpers";
import { useAppSelector } from "../../hooks/useReduxMethods";
import { getBussinessCurrency } from "../../shared/helpersMethod";
import { getDiscountPrice } from "../../helpers/product";
const { createSlice } = require("@reduxjs/toolkit");
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
  },
  reducers: {
    addToCart(state, action) {
      const { translation, ...product } = action.payload;
      const cartItem = state.cartItems.find((item) => item.id === product.id);
      const currency = getBussinessCurrency();

      if (!product.variation) {
        const cartItem = state.cartItems.find((item) => item.id === product.id);
        if (!cartItem) {
          state.cartItems.push({
            ...product,
            is_discount:
              getDiscountPrice(product.discount) !== null ? "Yes" : "No",
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuidv4(),
          });
        } else {
          state.cartItems = state.cartItems.map((item) => {
            if (item.cartItemId === cartItem.cartItemId) {
              return {
                ...item,
                is_discount:
                  getDiscountPrice(product.discount) !== null ? "Yes" : "No",
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity + 1,
                //   actual_price: product.price,
                //   is_discount: product.discount !== null ? "Yes" : "No",
                //   is_for_whole_order: "No",
                //   single_item_name: "",
                //   single_item_price: product.price,
                //   single_options: null,
                //   multiple_option: [],
                //   description: { en: "", ar: "" },
                //   color_size: null,
                //   affiliation: "Direct",
                //   item_brand: "test-resto",
                //   item_category: product.category[0] || "",
                //   item_category2: "",
                //   item_category3: "",
                //   item_category4: "",
                //   item_category5: "",
              };
            }
            return item;
          });
        }
      } else {
        // console.log(product.variation);
        // const cartItem = state.cartItems.find(
        //   (item) =>
        //     item.id === product.id &&
        //     // product.selectedProductColor &&
        //     // product.selectedProductColor === item.selectedProductColor &&
        //     // product.selectedProductSize &&
        //     // product.selectedProductSize === item.selectedProductSize &&
        //     (product.cartItemId
        //       ? product.cartItemId === item.cartItemId
        //       : true)
        // );
        state.cartItems.push({
          ...product,
          is_discount: product.discount !== null ? "Yes" : "No",
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuidv4(),
        });
      }

      var jsonBody = getReqJsonModalGA4WithoutReqData(
        "product-page",
        "simple",
        {
          currency: currency,
          value: product.price,
          items: [
            {
              id: product.prdId,
              item_id: product.prdId,
              item_name: product.name,
              currency: currency,
              price: product.price,
              item_category: product.category?.[0] || "",
              item_category2: "",
              item_category3: "",
              quantity: product.quantity,
            },
          ],
        }
      );
      // console.log(jsonBody);
      TrackGoogleAnalyticsEvent(
        "add_to_cart",
        "add_to_cart",
        "add_to_cart",
        jsonBody
      );
      toast.success(translation, {
        position: "top-center",
        toastId: "cart-success",
      });
    },
    deleteFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.cartItemId !== action.payload.cartItemId
      );
      toast.error(action.payload.translation, {
        position: "top-center",
        toastId: "cart-error",
      });
    },
    decreaseQuantity(state, action) {
      const { translation, ...product } = action.payload;
      if (product.quantity === 1) {
        state.cartItems = state.cartItems.filter(
          (item) => item.cartItemId !== product.cartItemId
        );
        toast.error(translation, { position: "top-center" });
      } else {
        state.cartItems = state.cartItems.map((item) =>
          item.cartItemId === product.cartItemId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );

        toast.warn(translation, {
          position: "top-center",
          toastId: "cart-decrement",
        });
      }
    },
    deleteAllFromCart(state) {
      state.cartItems = [];
    },
  },
});

export const {
  addToCart,
  deleteFromCart,
  decreaseQuantity,
  deleteAllFromCart,
} = cartSlice.actions;
export default cartSlice.reducer;
