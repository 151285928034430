// store.ts

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import productReducer from "./slices/product-slice";
import currencyReducer from "./slices/currency-slice";
import cartReducer from "./slices/cart-slice";
import categoriesReducer from "./slices/categories-nav-menu";
import cartReducerDukany from "./slices/cart-slice-dukany";
import compareReducer from "./slices/compare-slice";
import breadCrums from "./slices/breadCrums";
import wishlistReducer from "./slices/wishlist-slice";
import bussinessDataSlice from "./slices/bussiness-data-slice";
import apiCallCount from "./slices/apiCallCount";
import utmCampaignSlice from "./slices/utm-slice";
import paymentGatewaysReducer from "./slices/gateways-slice";
import progressStateReducer from "./slices/progressState";
const persistConfig = {
  //@ts-ignore
  key: window.businessByDomain || "root",
  version: 1.1,
  storage,
  blacklist: ["product"],
};

const rootReducer = combineReducers({
  apiCallCounter: apiCallCount,
  utmCampaignSlice: utmCampaignSlice,
  paymentGateways: paymentGatewaysReducer,
  bussinessData: bussinessDataSlice,
  progressState: progressStateReducer,
  breadCrumData: breadCrums,
  product: productReducer,
  currency: currencyReducer,
  homePageCategories: categoriesReducer,
  cart: cartReducerDukany,
  // dukanyCart: cartReducer,
  compare: compareReducer,
  wishlist: wishlistReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
