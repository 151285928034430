import { toast } from "react-toastify";
import {
  IBusinessMetas,
  ICUSTOMERINFO,
  IHOMEPAGEData,
  IRESAllData,
} from "./commonUtils";
export const hideAnnouncementBar = () => {
  localStorage.setItem("headerBar", false);
};
export const showAnnouncementBar = () => {
  localStorage.setItem("headerBar", true);
};
export const getShowAnnouncementBar = () => {
  var isShow = true;
  const fromStorage = localStorage.getItem("headerBar");
  if (null !== fromStorage) {
    isShow = Boolean(fromStorage);
  }
  return isShow;
};
const setBussinessToken = (newToken: string) => {
  localStorage.setItem(
    "bussinessData",
    JSON.stringify({
      accessToken: newToken,
    })
  );
};
export const setBussinessCountry = (bussinessCountry: any) => {
  var prev = localStorage.getItem("bussinessData");
  if (prev !== null) {
    prev = JSON.parse(prev);
    localStorage.setItem(
      "bussinessData",
      JSON.stringify({
        ...prev,
        businessCountry: bussinessCountry,
      })
    );
  }
};
export const setUtmParameters = (data: any) => {
  localStorage.setItem("utmInfo", JSON.stringify(data));
};
export const removeUtmParameters = () => {
  localStorage.removeItem("utmInfo");
};
export const getUtmParameters = (data: any) => {
  var utmInfo = localStorage.getItem("utmInfo");
  if (utmInfo !== null) {
    var utmInfo = localStorage.getItem("utmInfo");
    utmInfo = JSON.parse(utmInfo);
    console.log(utmInfo);
    return utmInfo;
  }
  return null;
};
const getBussinessCountry = (bussinessCountry: any) => {
  var country = localStorage.getItem("bussinessData");
  if (country !== null) {
    country = JSON.parse(country);
    country = country.businessCountry;
  }
  return country;
};
const setBussinessCurrency = (currency: string) => {
  localStorage.setItem("bussinessCurrency", currency);
};
const getBussinessCurrency = () => {
  var currency = "AED";
  var getCurrency = localStorage.getItem("bussinessCurrency");
  if (null !== getCurrency) {
    currency = getCurrency;
  }
  return currency;
};
const getBussinessData = () => {
  var data = localStorage.getItem("bussinessData");
  if (data !== null) {
    return JSON.parse(data);
  }
  return null;
};

const checkBussinessToken = async () => {
  var bussinessData = await getBussinessData(); // Wait for the data to be fetched

  if (bussinessData === null) {
    // If data is null, try again after 1 second
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
    bussinessData = await getBussinessData(); // Fetch again
  }
  return bussinessData;
};
export const getFinalTotalPrice = (
  bussinessData: IHOMEPAGEData,
  totalValue: number,
  deliveryFee: any,
  monthlyInstallmentValue: number
) => {
  var finalValue = totalValue;
  var decimalValue = bussinessData.isDecimalEnabled.decimal_place || 0;
  if (monthlyInstallmentValue > 0) {
    finalValue = monthlyInstallmentValue + parseFloat(deliveryFee);
    return numberWithCommas(finalValue.toFixed(decimalValue));
  }
  if (null !== deliveryFee && deliveryFee) {
    finalValue = totalValue + parseFloat(deliveryFee);
  }
  return numberWithCommas(finalValue.toFixed(decimalValue));
};
const setCustomerInfoFromStroge = (customerInfo: ICUSTOMERINFO) => {
  localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
};
const getCustomerInfoFromStroge = () => {
  var localValue = localStorage.getItem("customerInfo");
  if (localValue !== null) {
    return JSON.parse(localValue);
  } else {
    return null;
  }
};

const saveUserPrefersLang = (lang: string) => {
  localStorage.setItem("userLanguage", lang);
};

export const UpdateBussinessInfo = (bussinessData: any) => {
  if (bussinessData && bussinessData !== null) {
    const favicon = document.getElementById("favicon");
    //@ts-ignore
    favicon.href = bussinessData?.site_settings?.siteFavicon;
  }

  //  businessSiteLogo
};
const getUserPrefersLang = () => {
  var language = localStorage.getItem("userLanguage");
  return language;
};
function numberWithCommas(x: number | string) {
  let country_name = getBussinessCountry();
  let num = Number(x);
  if (country_name !== null && country_name == "Iraq") {
    let num = Math.round(Number(x));
  }

  let usFormat = num.toLocaleString("en-US");
  return usFormat;
}

function makeRtlCss() {
  document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
  document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
}
const TOKEN_EXPIRIES_MESSAGE = [
  "No token is found with request!",
  "No user found against this token!",
];

export {
  checkBussinessToken,
  TOKEN_EXPIRIES_MESSAGE,
  makeRtlCss,
  numberWithCommas,
  setCustomerInfoFromStroge,
  setBussinessToken,
  getBussinessData,
  getCustomerInfoFromStroge,
  saveUserPrefersLang,
  getUserPrefersLang,
  setBussinessCurrency,
  getBussinessCurrency,
};

export const showErrortMessage = (message: string, timeout?: number) => {
  toast.error(message, {
    position: "top-center",
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    toastId: "error",
    autoClose: timeout ? timeout : 1000,
  });
};
export const showSuccesstMessage = (message: string, timeout?: number) => {
  toast.success(message, {
    position: "top-center",
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    toastId: "success",
    autoClose: timeout ? timeout : 1000,
  });
};

// If you want to work with JavaScript Date objects and ensure the current date reflects a specific timezone (not the system's local timezone), you can adjust the Date object manually by calculating the time offset for the target timezone.

// Here’s how you can achieve this:

// Code to Adjust JavaScript Date Object for Specific Timezone
// javascript
export function getDateForTimezone(timezone) {
  // Get the current date in UTC
  const currentDate = new Date();

  // Convert the current UTC time to the target timezone
  const options = { timeZone: timezone, hour12: false };
  const localeString = currentDate.toLocaleString("en-GB", options);

  // Split the locale string to extract date and time parts
  const [datePart, timePart] = localeString.split(", ");
  const [day, month, year] = datePart.split("/");
  const [hours, minutes, seconds] = timePart.split(":");

  // Create a new Date object with the target timezone's date and time
  const adjustedDate = new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  );

  return adjustedDate;
}

export function preventGoogleFonts(): void {
  // we override these methods only for one particular head element
  // default methods for other elements are not affected
  const head = document.getElementsByTagName("head")[0];

  if (head) {
    const trueInsertBefore = head.insertBefore.bind(head);

    // TODO: adding return before reflect solves the TS issue

    head.insertBefore = function insertBefore<T extends Node>(
      newElement: T,
      referenceElement: HTMLElement
    ): T {
      if (isGoogleFontStyle(newElement)) {
        Reflect.apply(trueInsertBefore, head, [newElement, referenceElement]);
      }
      return newElement;
    };

    const trueAppend = head.appendChild.bind(head);

    // TODO: adding return before reflect solves the TS issue

    head.appendChild = function appendChild<T extends Node>(textNode: T): T {
      if (isGoogleFontStyle(textNode)) {
        Reflect.apply(trueAppend, head, [textNode]);
      }
      return textNode;
    };
  }
}
