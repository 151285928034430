import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HeroSliderThreeSingle = ({ data }) => {
  return (
    <div className="mobile-banner slider-height-7- bg-glaucous d-flex-1 align-items-center- ">
      <div className="">
        <div className="row- /*align-items-center*/ slider-h9-mrg--">
          <div>
            <div className="slider-singleimg-hm9 single-img-banner custom-test text-center">
              <picture className="single-img-banner">
                <source
                  media="(max-width : 932px)"
                  srcSet={data.mobile_image}
                />
                {/* <LazyLoadImage
                  media="(max-width : 932px)"
                  srcSet={data.mobile_image}
                  effect="blur"
                  rel="preload"
                  wrapperProps={{
                    style: { transitionDelay: "1s" },
                  }}
                  alt={window.businessByDomain + "-hero-slider"}
                  className="desktop-img-banner"
                  src={data.image}
                  decoding="async"
                /> */}
                <img
                  alt={window.businessByDomain + "-hero-slider"}
                  className="desktop-img-banner"
                  src={data.image}
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroSliderThreeSingle.propTypes = {
  data: PropTypes.shape({}),
};

export default HeroSliderThreeSingle;
