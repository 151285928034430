import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxMethods";
import { ICATEGORY } from "../../shared/commonUtils";
import { setBreadCrumCategoryName } from "../../store/slices/breadCrums";
interface INavMenuProps {
  homePageCategories: ICATEGORY[];
  sidebarMenu?: any;
  menuWhiteClass?: any;
}
const NavMenu: React.FC<INavMenuProps> = (props: INavMenuProps) => {
  const { menuWhiteClass, sidebarMenu } = props;
  const dispatch = useAppDispatch();
  const { homePageCategories } = useAppSelector(
    (state) => state.homePageCategories
  );

  const updateCategoryBreadCrums = (item: any) => {
    dispatch(
      setBreadCrumCategoryName({
        name: item.name,
        id: item.unique_id!,
        slug: item.slug,
      })
    );
  };
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          {/* main loop for displaying names */}
          {homePageCategories &&
            homePageCategories?.map((item: any, index) => {
              return (
                <>
                  {
                    <li
                      className={index >= 5 ? "hidden-category" : ""}
                      key={item.unique_id}
                    >
                      <Link
                        className="bold"
                        onClick={() => updateCategoryBreadCrums(item)}
                        to={
                          process.env.PUBLIC_URL +
                          `/collections/${item.slug}/${item.unique_id}`
                        }
                      >
                        {item.name}

                        {/* {t("TV & Audio")} */}
                        {item.childern.length !== 0 && (
                          <span>
                            {sidebarMenu ? (
                              <span>
                                <i className="fa fa-angle-right"></i>
                              </span>
                            ) : (
                              <i className="fa fa-angle-down" />
                            )}
                          </span>
                        )}
                      </Link>

                      {item.childern.length > 0 && (
                        <ul className="mega-menu mega-menu-padding">
                          {/*  loop for displaying nav menu */}
                          {item.childern.map((itemChild: any) => {
                            return (
                              <>
                                {itemChild.name !== "ImageCategory" && (
                                  <li key={itemChild.unique_id}>
                                    <ul>
                                      <li className="mega-menu-title bold">
                                        <Link
                                          onClick={() =>
                                            updateCategoryBreadCrums(itemChild)
                                          }
                                          to={
                                            process.env.PUBLIC_URL +
                                            `/collections/${itemChild.slug}/${itemChild.unique_id}`
                                          }
                                        >
                                          <span className="underline">
                                            {itemChild.name}
                                          </span>

                                          {/* {t("home_group_two")} */}
                                        </Link>
                                      </li>
                                      {/* loop for categories childeerns */}
                                      {itemChild.childern.map(
                                        (itemChild1: any) => {
                                          return (
                                            <li key={itemChild1.unique_id}>
                                              <Link
                                                onClick={() =>
                                                  updateCategoryBreadCrums(
                                                    itemChild1
                                                  )
                                                }
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  `/collections/${itemChild1.slug}/${itemChild1.unique_id}`
                                                }
                                              >
                                                {itemChild1.name}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </li>
                                )}
                              </>
                            );
                          })}
                          {item.category_image !== "" && (
                            <li>
                              <ul>
                                <li className="mega-menu-img">
                                  <a href="/">
                                    <img
                                      // height={200}
                                      // width={300}
                                      src={item.category_image}
                                      alt=""
                                    />
                                  </a>
                                </li>
                              </ul>
                            </li>
                          )}
                        </ul>
                      )}
                    </li>
                  }
                </>
              );
            })}
          {homePageCategories.length > 5 && (
            <li className="see-more bold">
              <Link>{t("txt_more")}</Link>
              <span>
                {sidebarMenu ? (
                  <span>
                    <i className="fa fa-angle-right"></i>
                  </span>
                ) : (
                  <i className="fa fa-angle-down" />
                )}
              </span>
              {homePageCategories.length > 0 && (
                <ul className="submenu">
                  {/*  loop for displaying nav menu */}
                  {homePageCategories.slice(5)?.map((item: any, index) => {
                    return (
                      <>
                        {item.name !== "ImageCategory" && (
                          <li key={index}>
                            <ul>
                              <li className="bold">
                                <Link
                                  onClick={() => updateCategoryBreadCrums(item)}
                                  to={
                                    process.env.PUBLIC_URL +
                                    `/collections/${item.slug}/${item.unique_id}`
                                  }
                                >
                                  <span className="underline">{item.name}</span>

                                  {/* {t("home_group_two")} */}
                                </Link>
                              </li>
                              {/* loop for categories childeerns */}
                              {/* {itemChild.childern.map((itemChild1: any) => {
                                return (
                                  <li key={itemChild1.unique_id}>
                                    <Link
                                      onClick={() =>
                                        updateCategoryBreadCrums(itemChild1)
                                      }
                                      to={
                                        process.env.PUBLIC_URL +
                                        `/collections/${itemChild1.slug}/${itemChild1.unique_id}`
                                      }
                                    >
                                      {itemChild1.name}
                                    </Link>
                                  </li>
                                );
                              })} */}
                            </ul>
                          </li>
                        )}
                      </>
                    );
                  })}
                  {/* {item.category_image !== "" && (
                    <li>
                      <ul>
                        <li className="mega-menu-img">
                          <a href="/">
                            <img
                              // height={200}
                              // width={300}
                              src={item.category_image}
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </li>
                  )} */}
                </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
