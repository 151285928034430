import { httpPost, httpGetWithAuth, httpGet } from "../../axios/axiosUtils";

import {
  ReceiverData,
  OrderResponse,
  AddressInterface,
  IPLACE_ORDER_REQ,
} from "../../shared/commonUtils";
import { useState } from "react";
// import useBussinessPage from "../useBussiness";
import { URL_CONSTANTS } from "../../axios/urlsLinkRjs";
import { useParams } from "react-router-dom";
import {
  getBussinessData,
  getCustomerInfoFromStroge,
  showSuccesstMessage,
} from "../../shared/helpersMethod";
import cogoToast from "cogo-toast";
const useCustomerInfo = () => {
  // const { getUserToken, userToken } = useBussinessPage();
  // const shopInfo = useSelector((state) => state.shopInfo);
  const [loading, setLoading] = useState(false);
  const [apiErrorMessage, setErrorMessage] = useState<string>("");
  const [couponLoader, setCouponLoader] = useState<boolean>(false);
  // const [success, setSuccess] = useState<number>(0);
  const [orderPlaced, setOrderPlaced] = useState<OrderResponse>();
  const [receiverInfo, setReceiverInfo] = useState<ReceiverData[]>([]);
  const bussinessToken = getBussinessData();
  const [trackOrderVal, setTrackOrderVal] = useState<any>();
  // const [cutomerData,setCustomerData] = useState<any>();
  const [userAddress, setUserAddress] = useState<AddressInterface>(
    {} as AddressInterface
  );

  const accessToken = null !== bussinessToken ? bussinessToken.accessToken : "";
  const customerInfo = getCustomerInfoFromStroge();
  const getCustomerInfo = async (mobile_number: string, name: string) => {
    var exsitingUser: boolean = false;
    var cutomerData: any = {};
    setLoading(true);
    try {
      // console.log(await sendHttpReq(shopInfo.accessToekn,strUtil.format(URL_CONSTANTS.get('URL_Get_Receipents'),{mobile_number:mobile_number,name:name}) ))
      // console.log(await sendHttpReq(shopInfo.accessToekn,URL_CONSTANTS.get('URL_Get_TrackOrder1')!))
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Create_Customer")!,
        {
          mobile_number,
          name,
        },
        {},
        accessToken
      );
      console.log(res);
      // setCustomerData(res.data)
      cutomerData = res.data;
      if (res.data.new_user === true) {
        exsitingUser = true;
      } else {
        exsitingUser = false;
      }

      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      // alert(error.message);
      setLoading(false);
    }
    return { exsitingUser, cutomerData };
  };
  const getOrderForCustometDiscountId = async (
    custId: string,
    discountId: string,
    access_token: string,
    discountCode?: string
  ) => {
    var orderDetails = [];
    try {
      if (discountCode) {
        setCouponLoader(true);
      }
      // setLoading(true);
      const response = await httpGetWithAuth(
        URL_CONSTANTS.get("URL_GET,Customer_Discount")! +
          `custId=${custId}&discountId=${discountId}${
            discountCode ? `&discountCode=${discountCode}` : ""
          }`,
        {},
        access_token
      );
      if (response.type === "success") {
        orderDetails = response?.data?.orderDetails;
        // if (discountCode) {
        orderDetails = response?.data;
        // }
        setCouponLoader(false);
        // setLoading(false);
      }
      setCouponLoader(false);
      // setLoading(false);
      return orderDetails;
    } catch (error) {
      setCouponLoader(false);
      // setLoading(false);
    }
  };
  /**
  const getValidateCustomer = async (mobile_number: string) => {
    var exsitingUser: boolean = false;
    var customerData: any = {};
    setLoading(true);
    try {
      const res = await httpPost(
        `/validate/customer?`,
        {
          mobile_number,
        },
        {},
        shopInfo.accessToekn
      );
      if (res.message === "Customer data found!") {
        exsitingUser = true;
        customerData = res.data;
      } else {
        exsitingUser = false;
        customerData = {};
      }
      setLoading(false);
    } catch (error: any) {
      exsitingUser = false;
      // console.log(error.message);
      customerData = {};
      // alert(error.message);
      setLoading(false);
    }
    return { exsitingUser, customerData };
  };
   */
  const loadReceipent = async () => {
    // console.log(await sendHttpReq(shopInfo.accessToekn,URL_CONSTANTS.get('URL_Get_TrackOrder1')!))
    // console.log(await sendHttpPost(shopInfo.accessToekn,URL_CONSTANTS.get('URL_Get_Receipents')!,{'customer_id':customer_id}))
    if (null !== customerInfo) {
      setLoading(true);
      try {
        const res = await httpPost(
          URL_CONSTANTS.get("URL_Load_Recipients")!,
          {
            customer_id: customerInfo?.id,
          },
          {},
          accessToken
        );
        // console.log(res);
        if (res.type === "success") {
          setReceiverInfo(res.data);
        }
        if (res.type === "error") {
          setReceiverInfo([]);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log(error.message);
      }
    }
  };
  const loadAddress = async (customer_id: number) => {
    setLoading(true);
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Load_Address")!,
        {
          customer_id,
        },
        {},
        accessToken
      );
      // console.log(res);
      if (res.type === "success") {
        setUserAddress(res.data);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const saveNewAddress = async (
    customer_id: number,
    instructions: string,
    address_label: string,
    location: string,
    address: string,
    latlng: any
  ) => {
    var success: boolean = false;
    var data: any = {};
    setLoading(true);
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Save_Address")!,
        {
          customer_id,
          instructions,
          address_label,
          location,
          address,
          latlng,
        },
        {},
        accessToken
      );
      if (res.type === "success") {
        success = true;
        data = res.data;
      } else {
        success = false;
      }
    } catch (error: any) {
      setLoading(false);
      success = false;
      console.log(error.message);
    }
    return { success, data };
  };
  const saveNewReceipent = async (
    recipient_name: string,
    recipient_phone: string,
    message?: string
  ) => {
    var success = false;
    var newId = 0;

    setLoading(true);
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Save_Recipient")!,
        {
          recipient_name,
          recipient_phone,
          customer_id: customerInfo?.id,
          message,
        },
        {},
        accessToken
      );
      if (res.type === "success") {
        success = true;
        newId = res.data.recipient_id;
        // setReceiverInfo(res.data);
      } else {
        success = false;
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      success = false;
      // console.log(error.message);
    }
    return { success, newId };
  };
  const saveNewGreetingMessage = async (
    access_token: any,
    reciever_id: string,
    customer_id: string,
    greeting_message: string
  ) => {
    var success = false;
    var messageId = 0;
    setLoading(true);
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Save_Greeting_Message")!,
        {
          customer_id,
          reciever_id,
          greeting_message,
        },
        {},
        access_token
      );
      console.log(res);
      if (res.type === "success") {
        setLoading(false);
        success = true;

        return { success };

        // setReceiverInfo(res.data);
      } else {
        setLoading(false);
        success = false;
        return { success };
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const updateOrderStatus = async (
    orderId: string,
    orderRef: string,
    orderStatus: string,
    access_token: string
  ) => {
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_POST_UPDATE_ORDER")!,
        {
          id: orderId,
          ref: orderRef,
          orderStatus: orderStatus,
        },
        {},
        access_token
      );

      // if (res.token === "expired") {

      //   await getUserToken("/" + bussinessName!, true).then((token) => {
      //     if (token) {
      //       updateOrderStatus(orderId, orderRef, orderStatus, token);
      //     }
      //     // console.log(token);
      //     // http://localhost:3000/test-resto/thankyou?order=26490&ref=1265&payment=card&reason=Success
      //   });

      //   // updateOrderStatus(orderId,orderRef,userToken!);
      // }
    } catch (error) {}
  };
  const updateUserAddress = async (addressId: string, customerId: string) => {
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Update_Address")!,
        {
          address_id: addressId,
          customer_id: customerId,
        },
        {},
        accessToken
      );
      cogoToast.success(res.data.message, { position: "top-center" });
    } catch (error) {}
  };

  const deleteAddress = async (address_id: string) => {
    setLoading(true);
    var success = false;
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_DELETE_ADDRESS")!,
        {
          address_id,
        },
        {},
        accessToken
      );
      showSuccesstMessage(res.data.message);
      if (res.type === "success") {
        setLoading(false);
        success = true;
        return success;
        // setReceiverInfo(res.data);
      } else {
        setLoading(false);
        success = false;
        return success;
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const getCustomerLoyaltyPoints = async (customerId: string) => {
    setLoading(true);
    var success = false;
    try {
      const res = await httpPost(
        `${URL_CONSTANTS.get("URL_GET_LOYALTY_POINTS")}customer_id=${customerId}`,
        {},
        {},
        accessToken
      );
      if (res.type === "success") {
        setLoading(false);
        success = true;
        var response = res;
        return { success, res };
      } else {
        setLoading(false);
        success = false;
        return success;
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const deleteReceiver = async (receiver_id: number) => {
    setLoading(true);
    var success = false;
    try {
      const res = await httpGetWithAuth(
        `${URL_CONSTANTS.get("URL_Delete_Recipient")}${receiver_id}`,
        {},
        accessToken
      );
      console.log(res);
      if (res.type === "success") {
        setLoading(false);
        success = true;
        return success;
        // setReceiverInfo(res.data);
      } else {
        setLoading(false);
        success = false;
        return success;
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const placeOrder = async (
    cartValues: IPLACE_ORDER_REQ,
    storeToken: string,
    bussinessName: string
  ) => {
    // var reqJson = JSON.stringify(cart);
    setLoading(true);
    var success = false;
    var orderValues: any = {};
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Confirmed_Order")!,
        {
          ...cartValues,
          // credit card check
        },
        {},
        storeToken
      );
      if (res.type === "success") {
        setOrderPlaced(res.data);
        success = true;
        orderValues = res.data;
        // setSuccess(1);
      } else {
        setErrorMessage("System unable to process request , Contact support");
      }
      // if (res.token === "expired") {
      //   getUserToken(bussinessName);
      // }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      success = false;
      console.log(e);
      //
      setErrorMessage("System unable to process request , Contact support");
      // if(e.response.data.data.errdesc){
      // setErrorMessage(e.response.data.data.errdesc);
      // }

      // setSuccess(2);
    }
    return { success, orderValues };
  };
  const trackingOrder = async (
    order_id: string,
    red_if: string,
    selectedLang: string
  ) => {
    setLoading(true);
    try {
      var res = await httpGet(
        `${URL_CONSTANTS.get("URL_Get_TrackOrder")}order=${order_id}&red_if=${red_if}&usrlang=${selectedLang}`

        // access_token
      );
      if (res.type === "success") {
        console.log(res.data);
        setTrackOrderVal(res.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  return {
    getCustomerInfo,
    // getValidateCustomer,
    receiverInfo,
    saveNewReceipent,
    updateOrderStatus,
    // cutomerData,
    deleteAddress,
    deleteReceiver,
    saveNewGreetingMessage,
    loadReceipent,
    apiErrorMessage,
    placeOrder,
    getCustomerLoyaltyPoints,
    updateUserAddress,
    couponLoader,
    loadAddress,
    saveNewAddress,
    trackingOrder,
    trackOrderVal,
    getOrderForCustometDiscountId,
    // success,
    orderPlaced,
    userAddress,
    loading,
  };
};
export default useCustomerInfo;
