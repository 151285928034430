import React, { Fragment } from "react";
import { ReactComponent as CartCross } from "../../../assets/svgs/CartCross.svg";
import ProductQuantity from "../../../components/product-qunatity/ProductQunatity";
import { ProductCartItem } from "../../../shared/commonUtils";
import { getDiscountPrice } from "../../../helpers/product";
import { useAppSelector } from "../../../hooks/useReduxMethods";
import useTranslationHook from "../../../hooks/useTranslationHook";
import {
  getCustomerInfoFromStroge,
  numberWithCommas,
} from "../../../shared/helpersMethod";
import ApplyCoupon from "../../../components/applyCoupon/ApplyCoupon";
interface ICheckoutPage {
  onPressIncrease: (cartItem: ProductCartItem) => void;
  onPressDecrease: (cartItem: ProductCartItem) => void;
  onPressDelete: (cartItem: ProductCartItem) => void;
  discountedPrice?: number | null;
  cartTotalPrice: number;
  cartTotalDiscount: number;
  cartOriginalTotal: number;
  onPressSubmit: () => void;
  ReceiverInfoBlock: any;
}
const CartItem = ({
  item,
  productPrice,
  onPressIncrease,
  onPressDecrease,
  onPressDelete,
}) => {
  const discountedPrice = getDiscountPrice(item.price, item.discount);
  const currency = useAppSelector((state) => state.currency);
  const finalProductPrice = (item.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = (
    discountedPrice! * currency.currencyRate
  ).toFixed(2);
  var productPrice = finalProductPrice;
  if (discountedPrice !== null) {
    productPrice = finalDiscountedPrice;
  }
  return (
    <Fragment>
      <div className="cart-item-d">
        <div className="cart-item__remove" onClick={() => onPressDelete(item)}>
          <CartCross />
        </div>
        <div className="d-flex ">
          <div className="cart-item__image">
            <img
              style={{ objectFit: "cover" }}
              width="148"
              height="127"
              src={item.image[0]}
            />
          </div>

          <div className="item-info">
            <h2 className="cart-item__name">{item.name}</h2>
            <p className="price">
              {discountedPrice !== null ? (
                <Fragment>
                  <span className="amount old">
                    {numberWithCommas(finalProductPrice) +
                      currency.currencySymbol}
                  </span>
                  <span className="amount">
                    {numberWithCommas(finalDiscountedPrice) +
                      currency.currencySymbol}
                  </span>
                </Fragment>
              ) : (
                <span className="amount ">
                  <span>{currency.currencySymbol}</span>
                  <span className="ml-2 mr-2">
                    {numberWithCommas(finalProductPrice)}
                  </span>
                </span>
              )}
              {/* <span>
                        {" "}
                        {numberWithCommas(cartItem.price) + " " + currency.currencySymbol}
                      </span> */}
            </p>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <ProductQuantity
              fromCart={true}
              quantity={item.quantity}
              onPressDecrease={(cartItem) => onPressDecrease(cartItem)}
              onPressIncrease={(cartItem) => onPressIncrease(cartItem)}
              itemObject={item}
            />
            <div className="d-flex ">
              <div className="mr-5 ml-5">Total</div>
              <p className="price">
                {discountedPrice !== null ? (
                  <Fragment>
                    <span className="amount old">
                      {numberWithCommas(finalProductPrice) +
                        currency.currencySymbol}
                    </span>
                    <span className="amount">
                      {numberWithCommas(finalDiscountedPrice) +
                        currency.currencySymbol}
                    </span>
                  </Fragment>
                ) : (
                  <span className="amount ">
                    <span className="ml-2 mr-2">
                      {numberWithCommas(finalProductPrice)}
                    </span>

                    <span>{currency.currencySymbol}</span>
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Cart = ({
  items,
  productPrice,
  onPressIncrease,
  onPressDecrease,
  onPressDelete,
}) => {
  return (
    <div className="cart-container">
      <h1 className="cart-container__title">Products in Cart:</h1>
      {items.map((item, index) => (
        <CartItem
          key={index}
          item={item}
          productPrice={productPrice}
          onPressDecrease={onPressDecrease}
          onPressDelete={onPressDelete}
          onPressIncrease={onPressIncrease}
        />
      ))}
    </div>
  );
};

const OrderSummary = ({
  cartTotalPrice,
  cartTotalDiscount,
  cartOriginalTotal,
  submitHandler,
}) => {
  const { t } = useTranslationHook();
  const currency = useAppSelector((state) => state.currency);
  return (
    <div className="order-summary">
      <h2>{t("order_summary")}</h2>
      <div className="summary-item">
        <h5 className="d-flex justify-content-between">
          <div> {t("txt_total_products")}</div>{" "}
          <span className="pl-15 pr-15">
            {numberWithCommas(cartOriginalTotal.toFixed(2)) +
              " " +
              currency.currencySymbol}
          </span>
        </h5>
      </div>
      <ApplyCoupon />
      <div className="summary-item">
        {/* <p>
          VAT: <strong>0 SAR</strong>
        </p> */}
        {cartTotalDiscount > 0 && (
          <div className="payment-row">
            <span className="title- text-danger">{t("discount_label")}</span>

            <span className="bold text-danger">
              {numberWithCommas(cartTotalDiscount.toFixed(2)) +
                " " +
                currency.currencySymbol}
            </span>
          </div>
        )}
        <div className="payment-row">
          <span className="title-">{t("txt_grand_total")}</span>
          <span className="bold">
            {numberWithCommas(cartTotalPrice.toFixed(2)) +
              " " +
              currency.currencySymbol}
          </span>
        </div>
      </div>
      {/* <div className="summary-item">
        <p>
          Final total: <strong>1688.48 SAR</strong>
        </p>
      </div> */}
      <button className="submit-btn" onClick={submitHandler}>
        {t("txt_proceed_to_checkout_new")}
      </button>
    </div>
  );
};

// const ReceiverInfo = () => {
//   return (
//     <div className="receiver-info">
//       <h2>Receiver Info (optional)</h2>
//       <div className="input-group">
//         <input type="text" placeholder="Receiver Name" />
//         <input type="text" placeholder="Receiver Number" />
//       </div>
//       <textarea placeholder="Greeting Message"></textarea>
//       <p className="char-count">Remaining characters: 600</p>
//     </div>
//   );
// };

const CheckoutPage: React.FC<ICheckoutPage> = (props: ICheckoutPage) => {
  const {
    cartItems,
    onPressIncrease,
    onPressDecrease,
    onPressDelete,
    cartTotalPrice,
    cartTotalDiscount,
    cartOriginalTotal,
    onPressSubmit,
    ReceiverInfoBlock,
  } = props;
  const customerInfo = getCustomerInfoFromStroge();
  return (
    <div className="checkout-page">
      <div className="checkout-page__content">
        <Cart
          items={cartItems}
          onPressIncrease={onPressIncrease}
          onPressDecrease={onPressDecrease}
          onPressDelete={onPressDelete}
        />
        {null !== customerInfo && ReceiverInfoBlock}
      </div>

      <OrderSummary
        submitHandler={onPressSubmit}
        cartTotalPrice={cartTotalPrice}
        cartTotalDiscount={cartTotalDiscount}
        cartOriginalTotal={cartOriginalTotal}
      />
    </div>
  );
};

export default CheckoutPage;
