import React from "react";
import useGetSearchData from "../../../hooks/useSearchInput";
import useTranslationHook from "../../../hooks/useTranslationHook";
import { Link } from "react-router-dom";
const SearchInput: React.FC = () => {
  const { t } = useTranslationHook();
  const { loading, getSearchResult, serachData } = useGetSearchData();
  const handleChange = (e: any) => {
    getSearchResult(e.target.value);
    // setSearchTerm(e.target.value);
    // Trigger search after a delay (e.g., 300ms)

    // debounceSearch(e.target.value);
  };
  return (
    <div className="search-content">
      <form action="#">
        <input
          type="text"
          placeholder={t("txt_search")!}
          onChange={handleChange}
        />
        <button className="button-search">
          <i className="pe-7s-search" />
        </button>
      </form>
      <div className="search-form-data ">
        {serachData.length > 0 &&
          serachData.map((item) => {
            return (
              <Link
                to={
                  item.source === "products"
                    ? `/product/${item.slug}/${item.data_id}`
                    : `/collections/${item.slug}/${item.data_id}`
                }
              >
                <div className="search-form-data-txt" key={item.data_id}>
                  {item.name}
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default SearchInput;
