//  const URL_BASE_THEME = 'https://adminv1.meemorder.io/api/rjs/';
const URL_BASE_THEME = "t/";
//  /set/customer/main/address?
export const apiVersion = 1;
const URL_CONSTANTS = new Map([
  ["URL_Get_Home", URL_BASE_THEME + "home"],
  ["URL_Get_Products", URL_BASE_THEME + "products"],
  ["URL_Get_Categories", URL_BASE_THEME + "categories"],
  ["URL_Get_BANNERS", URL_BASE_THEME + "get/banners"],
  ["URL_GET_TOKEN", "rjs/business/by/name/"],
  ["URL_Get_HomePageAllData", URL_BASE_THEME + "business"],
  ["URL_PRODUCTS_CATEGORY", URL_BASE_THEME + "products/by/category"],
  ["URL_PRODUCTS_BRANDS", URL_BASE_THEME + "products/by/brand"],
  ["URL_PRODUCTS_CATEGORY_FILTER", URL_BASE_THEME + "product/by/filters"],
  ["URL_GET_PRODUCT", URL_BASE_THEME + "product/by/id"],
  ["URL_PLACE_ORDER", URL_BASE_THEME + "place/order"],
  ["URL_EXTERNAL_PAGES", URL_BASE_THEME + "page?slug="],
  [
    "URL_PRODUCTS_CATEGORY_FILTER_DYNAMIC",
    URL_BASE_THEME + "search/product/by/filters",
  ],
  ["URL_Search_Data", URL_BASE_THEME + "search/data"],
  ["URL_ENTER_REVIEW", URL_BASE_THEME + "save/customer/view"],
  ["URL_AQSATI_GETINFO", URL_BASE_THEME + "installment/get/customer/info"],
  ["URL_AQSATI_VALIDATE", URL_BASE_THEME + "installment/validate"],
  // 'https://orderapi.dukany.io/api/business/gateways/lists'
  ["URL_GET_GATEWAYS", "business/gateways/lists"],
  ["URL_GET_BUSSINESS_RATING", URL_BASE_THEME + "getGoogleRating"],
  ["URL_TABBY_GETINFO", URL_BASE_THEME + "validate/tabby/customer"],
]);
export { URL_CONSTANTS };
