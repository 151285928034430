import React from "react";

const BussinessAppLink = () => {
  return (
    <div
      style={{ background: "#F3F4F6" }}
      className="d-flex flex-column gap-1 align-items-center pt-30 pb-50 "
    >
      <div className="text-start mb-10">تحميل تطبيق الجوال</div>
      <div class="d-flex gap-3">
        <a
          href="https://apps.apple.com/app/id6741459702"
          rel="noreferrer"
          target="_blank"
          class="s-apps-icons-item"
          id="app-slot"
        >
          <img
            alt="appstore"
            src={require("../../assets/images/appstore.webp")}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.watinia.ncdecommerce"
          rel="noreferrer"
          target="_blank"
          class="s-apps-icons-item"
          id="app-slot"
        >
          <img
            alt="googleplay"
            src={require("../../assets/images/googleplay.webp")}
          />
        </a>
      </div>
    </div>
  );
};

export default BussinessAppLink;
