import PropTypes from "prop-types";
import clsx from "clsx";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ProductGridThree from "./ProductGridThree";
import { HomepageCarouselSlider, Product } from "../../shared/commonUtils";
import { useEffect, useState } from "react";
interface ITabProductThree {
  spaceBottomClass: any;
  category: any;
  homepageCarouselSlider: HomepageCarouselSlider[];
}
const TabProductThree: React.FC<ITabProductThree> = (
  props: ITabProductThree
) => {
  const { spaceBottomClass, category, homepageCarouselSlider } = props;
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [selectedTabProducts, setSelectedTabProducts] = useState<Product[]>([]);
  const switchTab = (selectedTab: any) => {
    setSelectedTab(selectedTab.title);
    setSelectedTabProducts(selectedTab.products);
  };
  useEffect(() => {
    if (homepageCarouselSlider && homepageCarouselSlider.length !== 0) {
      setSelectedTab(homepageCarouselSlider[0].title);
      setSelectedTabProducts(homepageCarouselSlider[0].products);
    }
  }, []);
  return (
    <div className={clsx("product-area hm9-section-padding", spaceBottomClass)}>
      <div className="container-fluid">
        <Tab.Container defaultActiveKey="bestSeller">
          <Nav
            variant="pills"
            className="product-tab-list pb-40 text-center pt-40"
          >
            {homepageCarouselSlider?.map((item, index) => {
              return (
                <Nav.Item className="" onClick={() => switchTab(item)}>
                  <Nav.Link
                    eventKey={selectedTab}
                    active={item.title === selectedTab}
                  >
                    <span className="prd-nav-title">{item.title}</span>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Tab.Content className="prduct-hm9-tab">
            <Tab.Pane eventKey={selectedTab} active>
              <div className="custom-row-4">
                <ProductGridThree
                  selectedTabProducts={selectedTabProducts}
                  category={category}
                  type="new"
                  limit={10}
                  spaceBottomClass="mb-25"
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

TabProductThree.propTypes = {
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default TabProductThree;
