import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  const { bussinessData } = useSelector((state) => state.bussinessData);
  const { t } = useTranslation();
  return (
    // height: 26px;
    // width: 103px;
    // object-fit: contain;
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo">
        <Link to={process.env.PUBLIC_URL + "/"}>
          {/* <div className="footer-bussiness-logo" style={{backgroundImage:`url(${footerLogo})`}}/> */}
          <img
            className="logo-header-img"
            alt={"footer-logo-" + window.businessByDomain}
            src={process.env.PUBLIC_URL + footerLogo}
            width={"100%"}
          />
        </Link>
        <div
          className={`${
            /*sideMenu ? "col-xl-3 col-sm-4" :*/ "col-lg-2 col-sm-6"
          }`}
        >
          <div
            className={`${
              /*sideMenu ? "footer-widget mb-30 ml-145" :*/ "footer-widget  mt-20"
            }`}
          >
            {"uae" ===
              bussinessData?.businessCountry?.country_code_in_letters?.toLowerCase() &&
              bussinessData?.businessMetas?.TRN_DISPLAY &&
              bussinessData?.businessMetas?.TRN_DISPLAY !== "" && (
                <div
                  style={{ maxHeight: 100, maxWidth: 180 }}
                  className="footer-list"
                >
                  <img
                    alt="tax-info"
                    width="130"
                    className=""
                    src={require("../../assets/images/CertifiedBussinees.webp")}
                  />
                  <div style={{ fontSize: "12px", color: "#5d5d5d" }}>
                    <span>TRN:</span>
                    <span>{bussinessData.businessMetas.TRN_DISPLAY}</span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
