import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IHOMEPAGEData,
  OtpResData,
  OtpRespone,
} from "../../shared/commonUtils";
import useTranslationHook from "../useTranslationHook";
import { URL_CONSTANTS, apiVersion } from "../../axios/urlsLinkRjs";
import { httpPost, httpPostDukany } from "../../axios/axiosUtils";
import {
  getBussinessData,
  showErrortMessage,
  showSuccesstMessage,
} from "../../shared/helpersMethod";
import { AxiosError, AxiosResponse } from "axios";
const useSendOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otpData, setOtpData] = useState<OtpRespone>();
  const [errorMessage, setErrorMessage] = useState("");
  const bussinessToken = getBussinessData();
  const dispatch = useDispatch();
  const { t } = useTranslationHook();
  const { bussinessData }: any = useSelector(
    (state: any) => state.bussinessData
  );
  const [customerData, setCustomerData] = useState<OtpResData>();
  // const shopInfo :any  = useSelector((state) => state);
  const sendOtpHandler = async (
    mobile_number: string,
    country: string,
    msgType: string
  ) => {
    try {
      setLoading(true);
      // console.log(bussinessData);
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Send_Otp")!,
        {
          id: bussinessData.businessID,
          mobile_number,
          name: bussinessData.businessName,
          msgType,
          lang: bussinessData.defaultLang,
          country,
        },
        {},

        bussinessToken.accessToken
      );
      if (res.type === "success") {
        setOtpData(res);
        showSuccesstMessage("Otp sent successfully");
        localStorage.setItem("otpDataTemp", JSON.stringify(res));
        // dispatch(setOtpResponse(res));
      }
      setErrorMessage(res.message);
      setLoading(false);
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
      console.log(error);
      // alert(error.message);
      setLoading(false);
    }
  };
  const validOtpHandler = async (
    req_id: string,
    mobile_number: string,
    otp: string
  ) => {
    setLoading(true);

    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Validate_Otp")!,
        {
          req_id,
          otp,
          mobile_number,
          apiVersion,
        },
        {},
        bussinessToken.accessToken
      );
      console.log(res);
      if (res.type === "success") {
        setCustomerData(res);
      } else {
        showErrortMessage(res.message);
        setErrorMessage(res.message);
      }

      setLoading(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response.data?.message) {
          showErrortMessage(t("txt_invalid_otp"));
          setErrorMessage(t("txt_invalid_otp"));
        }
      }
      console.log(error);
      setLoading(false);
    }
  };
  return {
    sendOtpHandler,
    validOtpHandler,
    loading,
    otpData,
    errorMessage,
    customerData,
  };
};
export default useSendOtp;
