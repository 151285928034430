const { createSlice } = require("@reduxjs/toolkit");

const utmCampaignSlice = createSlice({
  name: "utmCampaignSlice",
  initialState: {
    order_source_url: "",
    pixel_id: "",
  },
  reducers: {
    setUtmParameters(state, action) {
      const values = action.payload;
      state.order_source_url = values.order_source_url;
      state.pixel_id = values.pixel_id;
    },
  },
});

export const { setUtmParameter } = utmCampaignSlice.actions;
export default utmCampaignSlice.reducer;
