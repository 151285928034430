import React from "react";
interface PaymentRowProp {
  labelValue: string | undefined;
  labelTitle: string;
  isShow: boolean | undefined;
  titleTextStyle?: any;
  valueTextStyle?: any;
}
const DetailRow: React.FC<PaymentRowProp> = (props: PaymentRowProp) => {
  const { labelValue, labelTitle, isShow, titleTextStyle, valueTextStyle } =
    props;
  return (
    <>
      {isShow && (
        <div
          className="cart_content_row d-flex justify-content-between "
          style={{ color: "#888D9A", paddingBottom: "0.75rem" }}
        >
          <div
            className="cart_content_col_70 flo_left"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <div style={{ ...titleTextStyle }}>{labelTitle}</div> */}
            <div
              style={{ ...titleTextStyle }}
              dangerouslySetInnerHTML={{ __html: labelTitle }}
            ></div>
          </div>
          {labelValue !== "N/A" && (
            <div className="cart_content_col_30" style={{ ...valueTextStyle }}>
              {labelValue}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DetailRow;
