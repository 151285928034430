import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import ProductGridSingleThree from "../../components/product/ProductGridSingleThree";
import { Product } from "../../shared/commonUtils";
import { useAppSelector } from "../../hooks/useReduxMethods";
import useWindowSize from "../../hooks/useScreenSize";
import useGetHomePage from "../../hooks/useGetHomePage";

interface IProductGridThree {
  selectedTabProducts: Product[];
  spaceBottomClass?: any;
  category?: any;
  type?: any;
  limit: any;
}

const ProductGridThree: React.FC<IProductGridThree> = (
  props: IProductGridThree
) => {
  const { spaceBottomClass, selectedTabProducts } = props;
  const currency = useAppSelector((state) => state.currency);
  const { cartItems } = useAppSelector((state) => state.cart);
  const { getHomePageBanners, homePageBanner } = useGetHomePage();
  const { wishlistItems } = useAppSelector((state) => state.wishlist);
  const { compareItems } = useAppSelector((state) => state.compare);
  const windowSize = useWindowSize();
  useEffect(() => {
    getHomePageBanners();
  }, []);
  // Sample banner image (you can replace this with your actual banner)
  const sampleBanner = require("../../assets/sampleBanner.png");

  const renderProduct = (product: Product, isFullWidth: boolean) => (
    <div
      key={product.id}
      className={
        isFullWidth
          ? "custom2-col-12 product-single-wrap"
          : "custom2-col-5 product-single-wrap"
      }
    >
      <ProductGridSingleThree
        fullWidth={isFullWidth}
        spaceBottomClass={spaceBottomClass}
        product={product}
        currency={currency}
        cartItem={cartItems.find((cartItem) => cartItem.id === product.id)}
        wishlistItem={wishlistItems.find(
          (wishlistItem) => wishlistItem.id === product.id
        )}
        compareItem={compareItems.find(
          (compareItem) => compareItem.id === product.id
        )}
      />
    </div>
  );

  const renderProductsWithBanner = () => {
    const productRows: JSX.Element[] = [];
    const first5Products = selectedTabProducts.slice(0, 5);
    const remainingProducts = selectedTabProducts.slice(5);
    // Render first two products normally
    first5Products.slice(0, 2).forEach((product, index) => {
      productRows.push(renderProduct(product, false));
    });

    // Render the remaining products after the banner
    first5Products.slice(2).forEach((product, index) => {
      const isFullWidth = windowSize.width < 768 && index === 0; // Make the third product full-width on mobile
      productRows.push(renderProduct(product, isFullWidth));
    });
    // Insert the banner after the first two products
    {
      windowSize.width < 768 &&
        homePageBanner &&
        productRows.push(
          <div key={"banner"} className="custom2-col-12 product-single-wrap">
            <img
              decoding="async"
              loading="lazy"
              onClick={() => {
                window.open(homePageBanner.banner_url, "_self");
              }}
              width={"100%"}
              src={homePageBanner.banner_image}
              alt="Sample Banner"
            />
          </div>
        );
    }
    remainingProducts.forEach((product, index) => {
      productRows.push(renderProduct(product, false));
    });
    return productRows;
  };

  return (
    <Fragment>{selectedTabProducts && renderProductsWithBanner()}</Fragment>
  );
};

ProductGridThree.propTypes = {
  spaceBottomClass: PropTypes.string,
};

export default ProductGridThree;
