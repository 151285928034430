import React, { useEffect } from "react";
// import BackArrow from "../../../../../assets/img/svgs/BackArrow";
// import PaymentRow from "./CartComponents/PaymentRow";
import useItemReceipt from "../../../hooks/useItemReceipt";
import DetailRow from "./DetailRow";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import ModalCloseButton from "../../../components/modal/components/ModalCloseButton";
import Loader from "../../../components/Loader";
import ButtonLoader from "../../../components/button-loader";
interface Props {
  onPressClose: () => void;
  show: boolean;
  orderDetails?: any;
}

// #A0AEBF
const OrderReceipt: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { getItemReceipt, loading, errorMessage, orderDetails } =
    useItemReceipt();
  let [searchParams] = useSearchParams();
  const refId = searchParams.get("ref");
  const orderId = searchParams.get("order");
  const showOrderDetails = searchParams.get("orderDetail");
  const RenderOrderHistory = ({ title, text }: any) => {
    return (
      <div style={{ padding: " 5px 0px 0px 0px" }}>
        <div className="OR_title">{title}</div>
        <div className="OR_Value">{text}</div>
      </div>
    );
  };
  const { onPressClose, show } = props;
  const getCorrectItemPrice = (item: any) => {
    // item.item_price.formatted_price;
    if (item.sub_items.length > 0) {
      return item.sub_items[0].price;
    }
    if (item.sub_items.length === 0) {
      return item.item_price.formatted_price;
    }

    return 0;
  };
  useEffect(() => {
    getItemReceipt(orderId, refId);
  }, [orderId, refId]);
  return (
    <Modal
      animation={false}
      show={show}
      className="product-quickview-modal-wrapper"
    >
      <div style={{ maxHeight: "600px", overflow: "scroll" }}>
        <ModalCloseButton onPressCross={onPressClose} />
        {!loading ? (
          <div
            id="orderReceipt"
            className="orderReceipt pop_up_maps_main pop_main_menu_bg"
          >
            <div className="popup_cart_full popup_from_bottom  popup_slide">
              <div style={{ padding: " 20px 30px " }}>
                <RenderOrderHistory
                  title={t("keyorder_number")}
                  text={"#" + refId}
                />
                <RenderOrderHistory
                  title={t("keyplaced_at")}
                  // text={"Wednesday, May 31, 2023 3:24 PM"}
                  // text={moment(orderDetails.order.created_at).format(
                  //   "dddd, MMMM D, YYYY h:mm A"
                  // )}
                  text={orderDetails?.order_info.order_placed}
                />
                <RenderOrderHistory
                  title={t("keydeliver_to")}
                  text={orderDetails?.customer.geo_location}
                />
                <RenderOrderHistory
                  title={t("keyorder_type")}
                  text={orderDetails?.order_info.order_type}
                />
                <RenderOrderHistory
                  title={t("keypayment_mode")}
                  text={orderDetails?.order_info.payment}
                />
                {/* <RenderOrderHistory
            title={"Order Status"}
            text={orderDetails?.order_info.status}
          /> */}

                <hr className="border-gray-200 my-7" />

                <div>
                  <div
                    className="OR_Value"
                    style={{ textAlign: "center", marginBottom: "1.25rem" }}
                  >
                    <span>{t("txt_your_order")}</span>
                  </div>
                  {orderDetails?.basket?.items.map((item, index) => {
                    return (
                      <div style={{ borderBottom: "1px solid #eee" }}>
                        <span>
                          <img
                            src={item.item_image}
                            style={{ borderRadius: "10px", margin: "0 3px" }}
                            height="40px"
                            width="40px"
                          />
                        </span>
                        <DetailRow
                          labelTitle={item.quantity + "x " + item.item_name}
                          labelValue={item.item_price.formatted_price}
                          //   labelValue={getCorrectItemPrice(item)}
                          isShow
                        />

                        {item.sub_items &&
                          item.sub_items.length > 0 &&
                          item.sub_items.map((item: any, index: number) => {
                            return (
                              <>
                                {/* {index === 0 && (
                            <div
                              style={{
                                color: "rgb(136, 141, 154)",
                                marginBottom: 2,
                              }}
                            >
                              Extras
                            </div>
                          )} */}
                                <DetailRow
                                  labelTitle={item.quantity + "x " + item.name}
                                  //   labelValue={item.item_price.formatted_price}
                                  labelValue={item.price}
                                  isShow
                                />
                              </>
                            );
                          })}
                      </div>
                    );
                  })}
                  <div style={{ marginTop: 30 }}></div>
                  <DetailRow
                    titleTextStyle={{
                      color: "black",
                      fontSize: 14,
                      // fontWeight: "500",
                    }}
                    valueTextStyle={{
                      color: "black",
                      fontSize: 14,
                      // fontWeight: "500",
                    }}
                    labelTitle={t("txt_cart_subtotal")}
                    labelValue={orderDetails?.basket.sub_total.formatted_price}
                    isShow
                  />

                  <DetailRow
                    titleTextStyle={{
                      color: "red",
                      fontSize: 14,
                    }}
                    valueTextStyle={{
                      color: "red",
                      fontSize: 14,
                    }}
                    labelTitle={t("Discount")}
                    labelValue={
                      orderDetails?.basket.discounted_amount.formatted_price
                    }
                    isShow={orderDetails?.basket.is_discount}
                  />

                  <DetailRow
                    titleTextStyle={{
                      color: "black",
                      fontSize: 14,
                    }}
                    valueTextStyle={{
                      color: "black",
                      fontSize: 14,
                    }}
                    labelTitle={t("discount_label")}
                    labelValue={
                      orderDetails?.basket.delivery_fee.formatted_price
                    }
                    isShow
                  />
                  <DetailRow
                    titleTextStyle={{
                      color: "red",
                      fontSize: 14,
                    }}
                    valueTextStyle={{
                      color: "red",
                      fontSize: 14,
                    }}
                    labelTitle={t("discount_on_delivery_fee")}
                    labelValue={
                      orderDetails?.basket.delivery_discounted_amount
                        .formatted_price
                    }
                    isShow={orderDetails?.basket.is_delivery_discount}
                  />
                  {orderDetails?.basket.is_loyalty && (
                    <DetailRow
                      titleTextStyle={{
                        color: "red",
                        fontSize: 14,
                      }}
                      valueTextStyle={{
                        color: "red",
                        fontSize: 14,
                      }}
                      labelTitle={t("loyaltyPoints")}
                      labelValue={
                        "-" +
                        orderDetails?.basket.loyalty_amount.formatted_price
                      }
                      isShow
                    />
                  )}

                  <hr className="border-gray-200 my-7" />

                  <div>
                    <DetailRow
                      titleTextStyle={{
                        color: "black",
                        fontSize: 16,
                        fontWeight: "500",
                      }}
                      valueTextStyle={{
                        color: "black",
                        fontSize: 14,
                        fontWeight: "500",
                      }}
                      labelTitle={t("txt_grand_total")}
                      labelValue={
                        orderDetails?.basket?.is_discount
                          ? orderDetails?.basket.discounted_grand_total
                              .formatted_price
                          : orderDetails?.basket.grand_total.formatted_price
                      }
                      isShow
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "600px",
              overflow: "scroll",
              justifyContent: "center",
            }}
          >
            {" "}
            <ButtonLoader height={20} width={20} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OrderReceipt;
